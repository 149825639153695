<template>


  <the-modal :isBig="true" :showCloseButton="false" @closeModal="closeModal">
   <template v-slot:header="">
     <h5 v-if="user!==null">Add to watch list</h5>
   </template>
   <template v-slot:body="">
    <div v-if="user==null" class="not-logged-in">
      <img src="/assets/logo/RefugeeCompass_Logo.png" class="logo" alt="Kiel.RefugeeCompass Logo">
      <center>
        <span>Log in to save content for later or simply create a profile now.</span>
        <div class="login-register-links">
          <button class="btn btn-hover btn-block" @click="LoginRoute">Log in</button>
          <button class="btn btn-hover btn-block login-btn" @click="RegisterRoute">Register</button>
        </div>
      </center>
    </div>
    <div v-else="" class="top-margin">
      <button class="btn btn-hover btn-fill icon-before" style="width:100%;" v-if="!isInFavorits" @click="addToFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true" data-wg-notranslate="">star</i>
          <span class="text-style">Save as favorite</span>
        </div>
      </button>

      <button class="btn btn-hover btn-fill icon-before mt10" style="width:100%;" v-else="" @click="removeFromFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true" data-wg-notranslate="">delete</i>
          <span class="text-style">Remove from favorites</span>
        </div>
      </button>

      <div class="mt20 text-center add-to-list" v-if="hasCollections">

        <button class="btn btn-hover btn-fill icon-before mt10" style="width:100%;" @click="toggleExpandableElement('list')">
          <div class="icon-text-box">
            <i class="material-icons" aria-hidden="true" data-wg-notranslate="">list</i>
            <span class="text-style">Add to list</span>
          </div>
        </button>
      </div>
      <div class="my-collections mt20" v-show="showList">

        <list-group style="margin-bottom: 20px;" v-if="userCollections.length > 0" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Own watch lists" :collections="userCollections" :expanded="true"></list-group>

        <list-group style="margin-bottom: 20px;" v-if="sharedCollections.length > 0" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Watch lists shared with me" :collections="sharedCollections" :expanded="false"></list-group>

        <list-group v-if="institutionCollections.length > 0" style="margin-bottom: 20px;" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Watch lists of my institution" :collections="institutionCollections" :expanded="false"></list-group>

        <div v-if="appsCollections.length>0">
          <list-group v-for="app in appsCollections" :key="app.name" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" :title="app.name + ' Listen'" :collections="app.collections" :expanded="false" style="margin-bottom: 20px;"></list-group>
        </div>

      </div>

      <div class="text-center new-collection-section">
        <span class="description-text">Create your own watch list to organize your favourites:</span>
        <button class="btn btn-hover btn-active btn-block" @click="showCreateModal">Create watch list</button>
      </div>
    </div>   
  </template></the-modal></template>