<template>


  <the-modal :isBig="true" :showCloseButton="false" @closeModal="closeModal">
   <template v-slot:header="">
     <h5 v-if="user!==null">Zur Merkliste hinzufügen</h5>
   </template>
   <template v-slot:body="">
    <div v-if="user==null" class="not-logged-in">
      <img src="/assets/logo/RefugeeCompass_Logo.png" class="logo" alt="Kiel.RefugeeCompass Logo">
      <center>
        <span>Melde dich an, um dir Inhalte für später zu merken oder erstelle dir jetzt ganz einfach ein Profil.</span>
        <div class="login-register-links">
          <button class="btn btn-hover btn-block" @click="LoginRoute">Log in</button>
          <button class="btn btn-hover btn-block login-btn" @click="RegisterRoute">Register</button>
        </div>
      </center>
    </div>
    <div v-else="" class="top-margin">
      <button class="btn btn-hover btn-fill icon-before" style="width:100%;" v-if="!isInFavorits" @click="addToFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true" data-wg-notranslate="">star</i>
          <span class="text-style">Als Favorit speichern</span>
        </div>
      </button>

      <button class="btn btn-hover btn-fill icon-before mt10" style="width:100%;" v-else="" @click="removeFromFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true" data-wg-notranslate="">delete</i>
          <span class="text-style">Aus Favoriten entfernen</span>
        </div>
      </button>

      <div class="mt20 text-center add-to-list" v-if="hasCollections">

        <button class="btn btn-hover btn-fill icon-before mt10" style="width:100%;" @click="toggleExpandableElement('list')">
          <div class="icon-text-box">
            <i class="material-icons" aria-hidden="true" data-wg-notranslate="">list</i>
            <span class="text-style">Zur Liste hinzufügen</span>
          </div>
        </button>
      </div>
      <div class="my-collections mt20" v-show="showList">

        <list-group style="margin-bottom: 20px;" v-if="userCollections.length > 0" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Eigene Merklisten" :collections="userCollections" :expanded="true"></list-group>

        <list-group style="margin-bottom: 20px;" v-if="sharedCollections.length > 0" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Mit mir geteilte Merklisten" :collections="sharedCollections" :expanded="false"></list-group>

        <list-group v-if="institutionCollections.length > 0" style="margin-bottom: 20px;" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" title="Merklisten meiner Institution" :collections="institutionCollections" :expanded="false"></list-group>

        <div v-if="appsCollections.length>0">
          <list-group v-for="app in appsCollections" :key="app.name" @addToList="beforeAddContentToCollection" @removeFromList="beforeRemoveContentFromCollection" :content="content" :title="app.name + ' Listen'" :collections="app.collections" :expanded="false" style="margin-bottom: 20px;"></list-group>
        </div>

      </div>

      <div class="text-center new-collection-section">
        <span class="description-text">Erstelle deine eigene Merkliste, um Favoriten zu ordnen:</span>
        <button class="btn btn-hover btn-active btn-block" @click="showCreateModal">Merkliste erstellen</button>
      </div>
    </div>   
  </template></the-modal></template>